import React from 'react';
import validator from 'validator';
import {FORM_ERROR_MESSAGES} from "../../Form";
import Input from "../Input";

/**
 * Text input component.
 */
class Text extends Input {
    /**
     * Text input constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Validate text input.
     *
     * @param value
     * @returns boolean
     */
    validate(value) {
        let errors = {};
        if (this.props.required) {
            if (validator.isEmpty(value, {ignore_whitespace: true})) {
                errors.isEmpty = FORM_ERROR_MESSAGES.isEmpty;
            }
        }
        if (this.props.maxLength) {
            if (!validator.isLength(value, {min: 0, max: this.props.maxLength})) {
                errors.maxLength = FORM_ERROR_MESSAGES.maxLength;
            }
        }
        if (this.props.minLength) {
            if (!validator.isLength(value, {min: this.props.minLength, max: undefined})) {
                errors.minLength = FORM_ERROR_MESSAGES.minLength;
            }
        }
        if (value && this.props.regex) {
            if (!validator.matches(value, this.props.regex, 'i')) {
                if (this.props.name === 'client_zip') {
                    errors.regex = FORM_ERROR_MESSAGES.zip;
                } else if (this.props.htmlType === 'tel') {
                    errors.regex = FORM_ERROR_MESSAGES.phoneNumber;
                } else {
                    errors.regex = FORM_ERROR_MESSAGES.regex;
                }
            }
        }
        if (this.props.email) {
            if (!validator.isEmail(value)) {
                errors.isEmail = FORM_ERROR_MESSAGES.isEmail;
            }
        }
        this.setState({errors: errors});
        return Object.getOwnPropertyNames(errors).length === 0;
    }

    /**
     * Render text input.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(<input type={this.props.htmlType} id={this.props.name}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur} value={this.props.value}/>)
        )
    }
}

export default Text;