import React, {Component} from 'react';

/**
 * Logo component.
 */
class Logo extends Component {
    /**
     * Render logo.
     *
     * @returns {*}
     */
    render() {
        return (
            <img src={this.props.url} alt={this.props.alt}/>
        )
    }
}

export default Logo;