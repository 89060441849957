import React, {Component} from 'react';
import Text from '../../Fields/Text';
import Textarea from '../../Fields/Textarea';
import Select from '../../Fields/Select';
import DatePicker from '../../Fields/DatePicker';
import FileUpload from '../../Fields/FileUpload';
import Checkbox from '../../Fields/Checkbox';
import Postcode from '../../Fields/Postcode';
import {FORM_TYPE_COMPLAINT} from "../../Form";

/**
 * Step two component.
 */
class StepTwo extends Component {

    /**
     * Component did mount event handler.
     */
    componentDidMount() {
        StepTwo.hideMobileKeyboardForDatepickers();
    }

    /**
     * Change datepicker inputs to readonly to disable showing of keyboard on mobile devices.
     * @see https://stackoverflow.com/a/57775723
     */
    static hideMobileKeyboardForDatepickers() {
        // do not add readonly on IE browsers
        if (!(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)) {
            const datePickers = document.getElementsByClassName('react-datepicker__input-container');
            Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute('readOnly', true)));
        }
    }

    /**
     * Prepare step fields for output.
     *
     * @returns {[]}
     */
    prepareFields() {
        let fields = [];
        for (const [fieldName, fieldData] of Object.entries(this.props.fields)) {
            switch (fieldData['type']) {
                case 'textarea':
                    fields.push(
                        <Textarea key={fieldName} name={fieldName} type="long" label={fieldData['label']}
                                  required={fieldData.validation && fieldData.validation.required}
                                  maxLength={fieldData.validation && fieldData.validation.max_length}
                                  tooltip={fieldData.tooltip && fieldData.tooltip}
                                  ref={fieldData.ref}
                                  value={fieldData.value}
                                  onChange={this.props.onFieldChange}/>
                    );
                    break;
                case 'select':
                    fields.push(
                        <Select key={fieldName} name={fieldName} type="long" label={fieldData['label']}
                                required={fieldData.validation && fieldData.validation.required}
                                tooltip={fieldData.tooltip && fieldData.tooltip}
                                ref={fieldData.ref}
                                value={fieldData.value}
                                onChange={this.props.onFieldChange}
                                options={fieldData.options}
                        />
                    );
                    break;
                case 'date':
                    fields.push(
                        <DatePicker key={fieldName} name={fieldName} type="long" label={fieldData['label']}
                                    required={fieldData.validation && fieldData.validation.required}
                                    tooltip={fieldData.tooltip && fieldData.tooltip}
                                    maxLength={fieldData.validation && fieldData.validation.max_length}
                                    ref={fieldData.ref}
                                    value={fieldData.value}
                                    format={fieldData.validation.date}
                                    onChange={this.props.onFieldChange}
                        />
                    );
                    break;
                case 'upload':
                    fields.push(<FileUpload key={fieldName} name={fieldName} type="long" ref={fieldData.ref}
                                            label={fieldData['label']} onUpdateFiles={this.props.onUpdateFiles}
                                            onRemoveFile={this.props.onRemoveFile} value={fieldData.value}
                                            required={fieldData.validation && fieldData.validation.required}
                                            tooltip={fieldData.tooltip && fieldData.tooltip}
                                            allowFileSizeValidation={fieldData.validation && fieldData.validation.hasOwnProperty('file_max_size')}
                                            maxFileSize={fieldData.validation && fieldData.validation.file_max_size}
                                            allowFileTypeValidation={fieldData.validation && fieldData.validation.hasOwnProperty('file_mimes')}
                                            acceptedFileTypes={fieldData.validation && fieldData.validation.file_mimes}
                                            acceptedFileExt={fieldData.validation && fieldData.validation.file_extensions}
                        />
                    );
                    break;
                case 'checkbox':
                    fields.push(<Checkbox key={fieldName} name={fieldName} type="checkbox" label={fieldData['label']}
                                          required={fieldData.validation && fieldData.validation.required}
                                          value={fieldData.value} onChange={this.props.onFieldChange}
                                          ref={fieldData.ref}
                        />
                    );
                    break;
                case 'postcode':
                    fields.push(
                        <Postcode key={fieldName} name={fieldName} type="long" label={fieldData['label']}
                                  required={fieldData.validation && fieldData.validation.required}
                                  tooltip={fieldData.tooltip && fieldData.tooltip}
                                  htmlType="text"
                                  minLength={fieldData.validation && fieldData.validation.min_length}
                                  maxLength={fieldData.validation && fieldData.validation.max_length}
                                  regex={fieldData.validation && fieldData.validation.regex}
                                  email={fieldData.validation && fieldData.validation.email}
                                  ref={fieldData.ref}
                                  value={fieldData.value}
                                  onChange={this.props.onFieldChange}/>
                    );
                    break;
                case 'info':
                    fields.push(<p key={fieldName}>{fieldData.content}</p>);
                    break;
                default:
                    // Default type text
                    fields.push(
                        <Text key={fieldName} name={fieldName} type="long" label={fieldData['label']}
                              required={fieldData.validation && fieldData.validation.required}
                              tooltip={fieldData.tooltip && fieldData.tooltip}
                              htmlType={fieldData.type}
                              minLength={fieldData.validation && fieldData.validation.min_length}
                              maxLength={fieldData.validation && fieldData.validation.max_length}
                              regex={fieldData.validation && fieldData.validation.regex}
                              email={fieldData.validation && fieldData.validation.email}
                              ref={fieldData.ref}
                              value={fieldData.value}
                              onChange={this.props.onFieldChange}/>
                    );
                    break;
            }
        }
        return fields;
    }

    /**
     * Render step two.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="form__content">
                {
                    this.props.formType === FORM_TYPE_COMPLAINT &&
                    <p className="form__info-text">Odpowiedzi na poniższe pytania pozwolą nam na wyjaśnienie Pani / Pana
                        zgłoszenia. Uprzejmie dziękujemy za poświęcony czas.</p>
                }
                {this.prepareFields()}
                <div className="form__line form__line-input__checkbox form__line-short-info">
                    <div className="form__line-inner">
                        <div className="form__line-label"><span className="form__label">Pola oznaczone gwiazdką są wymagane</span>
                        </div>
                        <div className="form__line-input">
                            <div className="form__input-container"><span className="form__warning-mark">*</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StepTwo;