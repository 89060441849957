import React, {Component} from 'react';
import axios from 'axios';
import Loader from "../Loader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Form from "../Form";
import './App.scss';
import CookieConsent from "../CookieConsent";

/**
 * Base api instance.
 *
 * @type {AxiosInstance}
 */
export const API_CLIENT = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

/**
 * App component.
 */
class App extends Component {
    /**
     * App config.
     *
     * @type {{}}
     */
    config = {};

    /**
     * Current state.
     *
     * @type {{isLoading: boolean, config: []}}
     */
    state = {
        error: false,
        isLoading: true,
        isSuccess: false
    };

    /**
     * App constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleFormSuccess = this.handleFormSuccess.bind(this);
    }

    /**
     * Get config api url.
     *
     * @param explain - append to end of url to disable cache and obtain exception explanation
     * @returns {string}
     */
    getConfigApiUrl(explain) {
        let url = process.env.REACT_APP_API_ENDPOINT_CONFIG + '/' + window.location.hostname;
        if (explain) {
            url += '/explain';
        }
        return url;
    }

    /**
     * Set success state if form submitted successfully.
     */
    handleFormSuccess() {
        this.setState({
            isSuccess: true
        });
    }

    /**
     * Fetch form configuration from API.
     */
    fetchConfig(explain = false) {
        API_CLIENT
            .get(this.getConfigApiUrl(explain))
            .then(response => {
                this.config = response.data.payload;
                this.appendRodoFieldToFormConfig();
                this.setState({
                    isLoading: false,
                });
                document.title = `${this.config.name} - Formularz kontaktowy`;
            })
            .catch(error => {
                this.setState({error: error, isLoading: false});
            });
    }

    /**
     * Called after app rendered.
     */
    componentDidMount() {
        this.fetchConfig(process.env.NODE_ENV === 'development');
    }

    /**
     * Render content.
     *
     * @returns {*}
     */
    renderContent() {
        return (
            this.state.error ? (
                <div className="form__sorry">
                    <div className="form_thanks-text-container">
                            <span
                                className="font__heading">Przepraszamy, wystąpił błąd.<br/>Proszę spróbować później.</span>
                    </div>
                </div>
            ) : (
                <div className="app__content">
                    {this.config.logo &&
                    <Header logo={this.config.logo} name={this.config.name} isSuccess={this.state.isSuccess}/>}
                    {this.config && <Form fields={this.config.form} onSuccess={this.handleFormSuccess}/>}
                </div>
            )
        )
    }

    /**
     * Append rodo field to enquiry and suggestion form types (not sent via api).
     */
    appendRodoFieldToFormConfig() {
        const rodoField = {
            step: 3,
            type: "checkbox",
            label: 'Wyrażam zgodę na przetwarzanie przez Grupę Maspex Sp. z o.o. Sp.k. z siedzibą w Wadowicach, adres: 34-100 Wadowice, ul. Legionów 37, danych osobowych w zakresie podanym w formularzu kontaktowym w celu udzielenia odpowiedzi na moją wiadomość/zapytanie. Mam świadomość, że mogę w każdym czasie wycofać udzieloną zgodę poprzez adres e-mail: iodkontakt@maspex.com. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Wysyłając niniejszy formularz jednocześnie potwierdzam, iż zapoznałam/łem się z informacjami o zasadach przetwarzania danych osobowych zawartymi w <a href="https://maspex.com/politykaprywatnosci.pdf" target="_blank">Polityce Prywatności</a>.',
            validation: {required: true}
        };
        this.config.form.enquiry.fields.rodo = rodoField;
        this.config.form.suggestion.fields.rodo = rodoField;
    }

    /**
     * Render app.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="app">
                <div className="app__container">
                    {this.state.isLoading ? <Loader/> : this.renderContent()}
                    <Footer/>
                    <CookieConsent/>
                </div>
            </div>
        );
    }
}

export default App;
