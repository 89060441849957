import Input from '../Input';
import React from 'react';
import {FilePond} from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import {API_CLIENT} from "../../../App/App";
import * as FilePondPlugin from "filepond";
import {FileOrigin} from "filepond";
import fileType from "file-type";
import {FORM_ERROR_MESSAGES} from "../../Form";
import './FileUpload.scss';

FilePondPlugin.registerPlugin(FilePondPluginFileValidateSize);
FilePondPlugin.registerPlugin(FilePondPluginFileValidateType);

/**
 * File upload form key.
 *
 * @type {string}
 */
const FILE_UPLOAD_KEY = 'media';

/**
 * File uplaod component.
 */
class FileUpload extends Input {
    /**
     * Files array, kept for serverId reference.
     *
     * @type {Array}
     */
    files = [];

    /**
     * FileUpload constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleProcessFile = this.handleProcessFile.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
        this.validate = this.validate.bind(this);
    }

    /**
     * Generate captcha token.
     *
     * @param item
     * @returns {Promise<unknown>}
     */
    generateCaptcha(item) {
        return new Promise(resolve => {
            if (item.origin === FileOrigin.INPUT) {
                window.grecaptcha.ready(function () {
                    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'upload'}).then(function (token) {
                        item.setMetadata('captcha', token);
                        return resolve(true);
                    });
                });
            }
            return resolve(true);
        });
    }

    /**
     * Prepare config for filepond component.
     *
     * @returns {{process: {url: *, ondata: (function(*): *)}, url: *}}
     */
    getUploadConfig() {
        return {
            url: process.env.REACT_APP_API_URL,
            process: {
                url: process.env.REACT_APP_API_ENDPOINT_UPLOAD,
                ondata: (formData) => {
                    let values = formData.getAll(FILE_UPLOAD_KEY);
                    for (const [index, data] of values.entries()) {
                        if (!(data instanceof File)) {
                            try {
                                let captcha = JSON.parse(data);
                                formData.append('captcha', captcha.captcha);
                            } catch (error) {

                            }
                            values.splice(index, 1);
                            formData.set(FILE_UPLOAD_KEY, values.pop());
                        }
                    }
                    return formData;
                },
                onload: (responseStr) => {
                    try {
                        let response = JSON.parse(responseStr);
                        if (response.status === 200) {
                            return response.payload.media;
                        }
                    } catch (error) {

                    }
                }
            },
            revert: (uniqueFileId, load, error) => {
                if (typeof uniqueFileId === 'object') {
                    for (const file of this.props.value) {
                        if (uniqueFileId === file.file) {
                            uniqueFileId = file.serverId;
                            break;
                        }
                    }
                }
                API_CLIENT
                    .delete(process.env.REACT_APP_API_ENDPOINT_UPLOAD + '/' + uniqueFileId)
                    .finally(load);
            }
        }
    }

    /**
     * Handle process file.
     *
     * @param error
     * @param file
     */
    handleProcessFile(error, file) {
        if (error) {
            return;
        }
        this.files.push(file);
    }

    /**
     * Handle remove file.
     *
     * @param error
     * @param file
     */
    handleRemoveFile(error, file) {
        if (error) {
            return;
        }
        this.files = this.files.filter((el) => {
            return el.file !== file.file;
        });
        this.props.onRemoveFile(this.props.name, file);
    }

    /**
     * Validate required file.
     *
     * @param value
     * @returns {boolean}
     */
    validate(value) {
        let errors = {};
        if (this.props.required) {
            if (!value.length) {
                errors.isFileRequired = FORM_ERROR_MESSAGES.isFileRequired;
            }
        }
        if (value.length) {
            if (value.filter(f => f.status !== 5 && f.status !== 2).length > 0) {
                errors.isFileUploading = FORM_ERROR_MESSAGES.isFileUploading;
            }
        }
        this.setState({errors: errors});
        return Object.getOwnPropertyNames(errors).length === 0;
    }

    /**
     * Render file upload field.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(
                <FilePond name={FILE_UPLOAD_KEY} itemInsertLocation="after" required={this.props.required}
                          allowDrop={true}
                          allowMultiple={true} beforeAddFile={this.generateCaptcha}
                          server={this.getUploadConfig()}
                          onprocessfile={this.handleProcessFile}
                          onprocessfiles={() => this.props.onUpdateFiles(this.props.name, this.files, this.validate)}
                          onupdatefiles={fileItems => this.props.onUpdateFiles(this.props.name, fileItems, this.validate)}
                          onremovefile={this.handleRemoveFile}
                          files={this.props.value}
                          allowFileSizeValidation={this.props.allowFileSizeValidation}
                          maxFileSize={this.props.maxFileSize}
                          allowFileTypeValidation={this.props.allowFileTypeValidation}
                          acceptedFileTypes={this.props.acceptedFileTypes}
                          fileValidateTypeDetectType={(source, type) =>
                              new Promise((resolve, reject) => {
                                      let ext = source.name.substr(source.name.lastIndexOf('.') + 1);
                                      if (!this.props.acceptedFileExt.includes(ext)) {
                                          reject();
                                      } else {
                                          let fileReader = new FileReader();
                                          fileReader.onloadend = (e) => {
                                              let fileInfo = fileType(new Uint8Array(e.target.result));
                                              if (!fileInfo) {
                                                  reject();
                                              } else {
                                                  resolve(fileInfo.mime);
                                              }
                                          };
                                          fileReader.readAsArrayBuffer(source);
                                      }
                                  }
                              )
                          }
                          labelIdle={'<span class="filepond--label-action">Wybierz</span> z dysku lub przeciągnij pliki tutaj'}
                          labelInvalidField={'Nieprawidłowy format pliku'}
                          labelFileWaitingForSize={'Oczekiwanie na rozmiar'}
                          labelFileSizeNotAvailable={'Rozmiar niedostępny'}
                          labelFileLoading={'Ładowanie'}
                          labelFileLoadError={'Wystąpił błąd podczas ładowania'}
                          labelFileProcessing={'Wysyłanie'}
                          labelFileProcessingComplete={'Wysyłanie zakończone'}
                          labelFileProcessingAborted={'Wysyłanie anulowane'}
                          labelFileProcessingError={'Wystąpił błąd podczas wysyłania'}
                          labelFileProcessingRevertError={'Wystąpił błąd podczas usuwania'}
                          labelFileRemoveError={'Wystąpił bład podczas usuwania'}
                          labelTapToCancel={'Kliknij aby anulować'}
                          labelTapToRetry={'Kliknij aby ponowić'}
                          labelTapToUndo={'Kliknij aby anulować'}
                          labelButtonRemoveItem={'Usuń'}
                          labelButtonAbortItemLoad={'Anuluj'}
                          labelButtonRetryItemLoad={'Ponów'}
                          labelButtonAbortItemProcessing={'Anuluj'}
                          labelButtonUndoItemProcessing={'Anuluj'}
                          labelButtonRetryItemProcessing={'Ponów'}
                          labelButtonProcessItem={'Wyślij'}
                          labelFileTypeNotAllowed={'Nieprawidłowy format pliku'}
                          fileValidateTypeLabelExpectedTypes={'Wymagany {allButLastType} lub {lastType}'}
                          labelMaxFileSizeExceeded={'Plik jest za duży'}
                          labelMaxFileSize={'Maksymalny rozmiar pliku to {filesize}'}
                          labelMaxTotalFileSizeExceeded={'Maksymalny rozmiar plików przekroczony'}
                          labelMaxTotalFileSize={'Maksymalny rozmiar plików to {filesize}'}
                />
            )
        )
    }
};

export default FileUpload;