import React from 'react';
import Input from '../Input';
import {default as ReactDatePicker, registerLocale, setDefaultLocale} from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import {format} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import validator from "validator";
import {FORM_ERROR_MESSAGES} from "../../Form";
import './DatePicker.scss';

registerLocale('pl', pl);
setDefaultLocale('pl');

/**
 * Date format map from php to js.
 *
 * @type {{"d-m-Y": string}}
 */
export const DATE_FORMAT = {
    'd-m-Y': 'dd-MM-yyyy',
    'm-Y': 'MM-yyyy'
};
export const DEFAULT_DATE_FORMAT = 'd-m-Y';

/**
 * DatePicker input component.
 */
class DatePicker extends Input {
    /**
     * Component state.
     *
     * @type {{date: null, errors: {}}}
     */
    state = {
        errors: {},
        tooltipVisible: false,
        date: this.props.value
    };

    /**
     * DatePicker constructor.
     *
     * @param props
     * @param context
     * @param state
     */
    constructor(props, context, state) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle date change event.
     *
     * @param event
     */
    handleChange(event) {
        this.setState({
            date: event
        });
        this.value = event;
        this.props.onChange(this.props.name, event);
    }

    /**
     * Handler for input blur action.
     *
     * @param event
     */
    handleBlur(event) {
        let value = this.value instanceof Date ? this.value : null;
        this.validate(value);
        this.props.onChange(this.props.name, value);
    }

    /**
     * Validate date.
     *
     * @param value
     * @returns {boolean}
     */
    validate(value) {
        let errors = {};
        let dateFormatted = value instanceof Date ? format(value, DATE_FORMAT[this.props.format]) : '';
        if (this.props.required) {
            if (validator.isEmpty(dateFormatted, {ignore_whitespace: true})) {
                errors.isEmpty = FORM_ERROR_MESSAGES.isEmpty;
            }
        }
        if (this.props.maxLength) {
            if (!validator.isLength(dateFormatted, {min: 0, max: this.props.maxLength})) {
                errors.maxLength = FORM_ERROR_MESSAGES.maxLength;
            }
        }
        if (value && !dateFormatted) {
            errors.dateFormat = FORM_ERROR_MESSAGES.dateFormat;
        }
        this.setState({errors: errors});
        return Object.getOwnPropertyNames(errors).length === 0;
    }

    /**
     * Render date.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(<ReactDatePicker selected={this.state.date} onChange={this.handleChange}
                                              onBlur={this.handleBlur}
                                              dateFormat={DATE_FORMAT[this.props.format]}
                                              showMonthYearPicker={this.props.format === 'm-Y'}
            />)
        );
    }
}

export default DatePicker;