import React, {Component} from 'react';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import Pager from './Pager/Pager';
import Button from './Fields/Button';
import Input from './Fields/Input';
import pl from 'date-fns/locale/pl';
import {format} from 'date-fns';
import Loader from "../Loader";
import {DATE_FORMAT, DEFAULT_DATE_FORMAT} from "./Fields/DatePicker/DatePicker";
import './Form.scss';
import {API_CLIENT} from "../App/App";
import {registerLocale, setDefaultLocale} from "react-datepicker";

registerLocale('pl', pl);
setDefaultLocale('pl');

/**
 * Form types.
 *
 * @type {string}
 */
export const FORM_TYPE_COMPLAINT = 'complaint';
export const FORM_TYPE_ENQUIRY = 'enquiry';
export const FORM_TYPE_SUGGESTION = 'suggestion';

/**
 * Validation error messages.
 */
export const FORM_ERROR_MESSAGES = {
    isEmpty: 'Wypełnienie tego pola jest wymagane',
    isChecked: 'Zaznaczenie tego pola jest wymagane',
    minLength: 'Podana wartość jest zbyt krótka',
    maxLength: 'Podana wartość jest zbyt długa',
    notExists: 'Podana wartość jest nieprawidłowa',
    dateFormat: 'Nieprawidłowy format daty',
    isFileRequired: 'Przesłanie pliku jest wymagane',
    isFileUploading: 'Proszę poczekać na zakończenie przesyłania',
    isEmail: 'Adres e-mail jest nieprawidłowy',
    regex: 'Nieprawidłowy format',
    phoneNumber: 'Nieprawidłowy format telefonu. Prawidłowy format: +48 555 555 555',
    zip: 'Nieprawidłowy format. Prawidłowy format: XX-XXX'
};

/**
 * Form type names.
 *
 * @type {{[p: string]: string, "[FORM_TYPE_SUGGESTION]": string, "[FORM_TYPE_ENQUIRY]": string}}
 */
export const FORM_TYPE_NAMES = {
    [FORM_TYPE_COMPLAINT]: 'Reklamacja produktowa',
    [FORM_TYPE_ENQUIRY]: 'Pytanie dotyczące produktu, składników, itd.',
    [FORM_TYPE_SUGGESTION]: 'Opinie, sugestie dotyczące produktów'
};

/**
 * Step titles.
 *
 * @type {*[]}
 */
const FORM_STEP_TITLES = [
    'Temat zgłoszenia',
    'Dane produktowe',
    'Dane kontaktowe'
];

const FORM_STEP_HASHES = {};
FORM_STEP_HASHES[FORM_TYPE_COMPLAINT] = [
    '',
    'ReklamacjaKrok2',
    'ReklamacjaKrok3',
    'ReklamacjaTYP'
];
FORM_STEP_HASHES[FORM_TYPE_ENQUIRY] = [
    '',
    'PytanieKrok2',
    'PytanieKrok3',
    'PytanieTYP'
];
FORM_STEP_HASHES[FORM_TYPE_SUGGESTION] = [
    '',
    'OpinieKrok2',
    'OpinieKrok3',
    'OpinieTYP'
];

/**
 * Form component.
 */
class Form extends Component {
    /**
     * State.
     *
     * @type {{currentStep: number, formType: *}}
     */
    state = {
        currentStep: 1,
        formType: FORM_TYPE_COMPLAINT,
        isSubmitting: false,
        isSuccess: false,
        isError: false,
        animate: false,
        globalError: ''
    };

    /**
     * Form constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFilesUpdate = this.handleFilesUpdate.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleGoToStep = this.handleGoToStep.bind(this);
    }

    /**
     * Component did mount event.
     */
    componentDidMount() {
        this.initFields(this.state.formType);
        this.createRefs();
        this.handleHashInit();
    }

    /**
     * Component did update event.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.animate === 'scrollToTop') {
            this.scrollToElement('app__main');
        } else if (this.state.animate === 'scrollToError') {
            this.scrollToElement('form__line-wrong');
        }
        this.handleHashChange();
    }

    /**
     * Fill state with field keys.
     *
     * @param formType
     */
    initFields(formType) {
        let fields = {};
        for (const [fieldName, fieldData] of Object.entries(this.props.fields[formType].fields)) {
            if (fieldData.type === 'date') {
                fields[fieldName] = null;
            } else if (fieldData.type === 'upload') {
                fields[fieldName] = [];
            } else {
                fields[fieldName] = '';
            }

        }
        this.setState(fields);
    }

    /**
     * Create refs for all form fields.
     */
    createRefs() {
        [FORM_TYPE_COMPLAINT, FORM_TYPE_ENQUIRY, FORM_TYPE_SUGGESTION].forEach(formTypeConfig => {
                Object.keys(this.props.fields[formTypeConfig].fields).forEach(fieldName => {
                    this.props.fields[formTypeConfig].fields[fieldName]['ref'] = React.createRef();
                })
            }
        );
    }

    /**
     * Get fields for form step depending on form type and step number. Fill value from current state.
     *
     * @param formType
     * @param stepNum
     */
    getStepFields(formType, stepNum = null) {
        let fields = {};
        for (let [fieldName, fieldData] of Object.entries(this.props.fields[formType].fields)) {
            if (fieldData.type === 'select') {
                fieldData = this.changeNumericSelectOptionValues(fieldData);
            }
            if (stepNum === null || fieldData['step'] === stepNum) {
                fields[fieldName] = fieldData;
                fields[fieldName]['value'] = this.state[fieldName];
            }
            if (formType === FORM_TYPE_COMPLAINT && stepNum === 2 && fieldName === 'product_image') {
                fields['complaint_info'] = {
                    type: 'info',
                    content: 'Aby kompleksowo i możliwie szybko odpowiedzieć na zgłoszenie, prosimy o podanie dodatkowych informacji:'
                };
            }
        }
        return fields;
    }

    /**
     * Change numeric array option values to json objects.
     *
     * @param fieldData
     * @returns {*}
     */
    changeNumericSelectOptionValues(fieldData) {
        if (Array.isArray(fieldData.options)) {
            let options = {};
            fieldData.options.forEach(option => {
                options[option] = option;
            });
            fieldData.options = options;
        }
        return fieldData;
    }

    /**
     * Handler for changing form type in step one.
     *
     * @param type
     */
    handleTypeChange(type) {
        this.setState({formType: type});
        this.initFields(this.state.formType);
    }

    /**
     * Handle default input field change.
     *
     * @param name
     * @param value
     */
    handleFieldChange(name, value) {
        this.setState({[name]: value});
    }

    /**
     * Handle files update (after file upload).
     *
     * @param name
     * @param files
     * @param callback
     */
    handleFilesUpdate(name, files, callback) {
        files = files.map(f => {
            // uploaded file should be stored as "limbo" in state
            f.options = f.options || {};
            f.options.type = 'limbo';
            // use previously stored reference to prevent losing of "serverId"
            let matched = this.state[name].filter(fs => fs.file === f.file);
            if (matched.length) {
                return matched.shift();
            }
            return f;
        });
        this.setState({[name]: files}, () => callback(files));
    }

    /**
     * Handle file remove (remove file from state).
     *
     * @param name
     * @param file
     */
    handleRemoveFile(name, file) {
        this.setState({
            [name]: this.state[name].filter((el) => {
                return el.file !== file.file;
            })
        });
    }

    /**
     * Validate step inputs.
     *
     * @param stepNum
     * @returns {boolean}
     */
    validateStep(stepNum) {
        let isValid = false;
        if (stepNum === 1) {
            isValid = true;
        } else if (stepNum === 2 || stepNum === 3) {
            let stepFields = this.getStepFields(this.state.formType, stepNum);
            let validCount = 0;
            for (const [fieldName, fieldData] of Object.entries(stepFields)) {
                if (fieldData.type === 'info') {
                    validCount++;
                } else {
                    validCount += fieldData.ref.current instanceof Input && fieldData.ref.current.validate(this.state[fieldName]);
                }
            }
            isValid = validCount === Object.keys(stepFields).length;
        }
        return isValid;
    }

    /**
     * Handler for next button click.
     */
    handleNextStep() {
        if (this.validateStep(this.state.currentStep)) {
            if (this.state.currentStep === 3) {
                this.handleSubmit();
            } else {
                this.setState({currentStep: this.state.currentStep + 1});
            }
            this.setState({animate: 'scrollToTop'});
        } else {
            this.setState({animate: 'scrollToError'});
        }
    }

    /**
     * Handler for prev button click.
     */
    handlePrevStep() {
        this.setState({currentStep: this.state.currentStep - 1});
    }

    /**
     * Handle go to step.
     *
     * @param stepNum
     */
    handleGoToStep(stepNum) {
        if (stepNum < this.state.currentStep) {
            this.setState({currentStep: stepNum});
        }
    }

    /**
     * Handle hash init.
     */
    handleHashInit() {
        if (window.location.hash && window.location.hash.length > 1) {
            let hash = window.location.hash.substr(1);
            switch (hash) {
                case 'PytanieKrok2':
                    this.setState({currentStep: 2, formType: FORM_TYPE_ENQUIRY});
                    break;
                case 'ReklamacjaKrok2':
                    this.setState({currentStep: 2, formType: FORM_TYPE_COMPLAINT});
                    break;
                case 'OpinieKrok2':
                    this.setState({currentStep: 2, formType: FORM_TYPE_SUGGESTION});
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Handle hash change.
     */
    handleHashChange() {
        if (!this.state.isSuccess) {
            if (this.state.currentStep === 1 && typeof window.history.pushState !== 'undefined') {
                window.history.replaceState(null, null, ' ');
            } else {
                window.location.hash = FORM_STEP_HASHES[this.state.formType][this.state.currentStep - 1];
            }
        }
    }

    /**
     * Handler for submit form action.
     */
    handleSubmit() {
        this.setState({isSubmitting: true});
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'submit'}).then((token) => {
                let formData = new FormData();
                formData.append('domain', window.location.hostname);
                formData.append('captcha', token);
                formData.append('form_type', this.state.formType);
                for (const [fieldName, fieldData] of Object.entries(this.getStepFields(this.state.formType))) {
                    if (fieldData.type === 'date') {
                        if (fieldData.value instanceof Date) {
                            const dateFormat = fieldData.validation && fieldData.validation.date ? DATE_FORMAT[fieldData.validation.date] : DATE_FORMAT[DEFAULT_DATE_FORMAT];
                            formData.append(fieldName, format(fieldData.value, dateFormat));
                        }
                    } else if (fieldData.type === 'upload') {
                        for (const file of fieldData.value) {
                            formData.append(fieldName + '[]', file.serverId);
                        }
                    } else if (fieldData.type !== 'info') {
                        formData.append(fieldName, fieldData.value);
                    }
                }
                API_CLIENT
                    .post(this.getSubmitApiUrl(process.env.NODE_ENV === 'development'), formData)
                    .then(response => {
                        window.location.hash = FORM_STEP_HASHES[this.state.formType][3];
                        this.setState({
                            currentStep: 1,
                            formType: FORM_TYPE_COMPLAINT,
                            isSubmitting: false,
                            isSuccess: true,
                            isError: false,
                            globalError: ''
                        });
                        this.props.onSuccess();
                        this.initFields(FORM_TYPE_COMPLAINT);
                    })
                    .catch(error => {
                        this.setState({
                            isError: true,
                            globalError: 'Wystąpił błąd podczas wysyłania formularza. Proszę spróbować ponownie.'
                        });
                        if (error.response && error.response.data && error.response.data.error && error.response.data.error.explain) {
                            if (error.response.data.error.explain.hasOwnProperty('client_email')) {
                                this.setState({globalError: 'Wystąpił błąd podczas wysyłania formularza: adres e-mail nie istnieje.'});
                            }
                        }
                    })
                    .finally(() => {
                        this.setState({isSubmitting: false});
                    });
            });
        })
    }

    /**
     * Get submit form api url.
     *
     * @param explain - append to end of url to disable cache and obtain exception explanation
     * @returns {string}
     */
    getSubmitApiUrl(explain) {
        let url = process.env.REACT_APP_API_ENDPOINT_SUBMIT + '/' + window.location.hostname;
        if (explain) {
            url += '/explain';
        }
        return url;
    }

    /**
     * Render form.
     *
     * @returns {*}
     */
    renderForm() {
        return (
            <div className="app__main">
                <div className="container block">
                    <div className="block__inner">
                        {
                            !this.state.isSuccess &&
                            <div className="block__top">
                                <div className="block__top-inner">
                                    <div className="block__top-label">
                                        <div className="block__top-label-text">
                                            {(this.state.currentStep === 2 || this.state.currentStep === 3) && <p className="subheading">{FORM_TYPE_NAMES[this.state.formType]}</p>}
                                        <span
                                            className="font__heading">{FORM_STEP_TITLES[this.state.currentStep - 1]}</span>
                                        </div>
                                    </div>
                                    <Pager currentStep={this.state.currentStep} onStepClick={this.handleGoToStep}/>
                                </div>
                            </div>
                        }
                        <div className="block__content">
                            <div className="block__content-inner">
                                {!this.state.isSuccess ? this.renderStep() : this.renderThanks()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container navigation">
                    {
                        this.state.currentStep === 3 && this.state.isError &&
                        <div className="form__alert form__alert-danger">{this.state.globalError}</div>
                    }
                    {
                        !this.state.isSuccess &&
                        <div className="navigation__inner">
                            {
                                this.state.currentStep === 1
                                    ? <span className="flexfill"></span>
                                    : <Button text="Poprzedni krok" onClick={this.handlePrevStep}
                                              additionalClasses="btn-p navigation__button-prev"/>
                            }
                            <Button text={this.state.currentStep === 3 ? 'Wyślij formularz' : 'Następny krok'}
                                    onClick={this.handleNextStep} additionalClasses="btn-a navigation__button-next"/>
                        </div>
                    }
                </div>
            </div>
        );
    }

    /**
     * Render form step.
     *
     * @returns {*}
     */
    renderStep() {
        let step;
        if (this.state.currentStep === 1) {
            step = <StepOne formType={this.state.formType} onTypeChange={this.handleTypeChange}/>;
        } else if (this.state.currentStep === 2) {
            step = <StepTwo formType={this.state.formType}
                            fields={this.getStepFields(this.state.formType, 2)}
                            onFieldChange={this.handleFieldChange}
                            onUpdateFiles={this.handleFilesUpdate}
                            onRemoveFile={this.handleRemoveFile}
            />;
        } else if (this.state.currentStep === 3) {
            step = <StepThree formType={this.state.formType}
                              fields={this.getStepFields(this.state.formType, 3)}
                              onFieldChange={this.handleFieldChange}
                              onUpdateFiles={this.handleFilesUpdate}
                              onRemoveFile={this.handleRemoveFile}/>;
        }
        return step;
    }

    /**
     * Render success thank you page.
     *
     * @returns {*}
     */
    renderThanks() {
        return (
            <div className="form__thanks">
                <div className="form_thanks-icon-container">
                    <div className="form__thanks-icon-circle">✔</div>
                </div>
                <div className="form_thanks-text-container">
                    <span className="font__heading">Zgłoszenie zostało wysłane, dziękujemy.</span>
                    <small>Na podany adres e-mail zostało przesłane potwierdzenie przyjęcia zgłoszenia.</small>
                </div>
            </div>
        )
    }

    /**
     * Scroll to dom element.
     *
     * @param className
     */
    scrollToElement = (className) => {
        const elems = document.getElementsByClassName(className);
        if (elems.length > 0) {
            if (elems[0]) {
                window.scrollTo({
                    top: elems[0].offsetTop,
                    behavior: 'smooth'
                });
            }
        }
        this.setState({animate: false});
    };

    /**
     * Render form.
     *
     * @returns {*}
     */
    render() {
        return (
            this.state.isSubmitting ? <Loader/> : this.renderForm()
        )
    }
}

export default Form;