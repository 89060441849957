import React from 'react';
import {FORM_ERROR_MESSAGES} from "../../Form";
import Input from "../Input";

/**
 * Checkbox component.
 */
class Checkbox extends Input {
    /**
     * Checkbox constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Validate checkbox.
     *
     * @param value
     * @returns boolean
     */
    validate(value) {
        let errors = {};
        if (this.props.required) {
            if (parseInt(value) !== 1) {
                errors.isChecked = FORM_ERROR_MESSAGES.isChecked;
            }
        }
        this.setState({errors: errors});
        return Object.getOwnPropertyNames(errors).length === 0;
    }

    /**
     * Handle input change event.
     *
     * @param event
     */
    handleChange(event) {
        this.value = this.value === 1 ? 0 : 1;
        this.validate(this.value);
        this.props.onChange(event.target.id, this.value);
    }

    /**
     * Handler for input blur action.
     *
     * @param event
     */
    handleBlur(event) {
        // nothing happens on checkbox blur action
    }

    /**
     * Render checkbox.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(<div>
                <input type="checkbox" id={this.props.name} onChange={this.handleChange}
                       onBlur={this.handleBlur} value="1" checked={this.props.value === 1}/>
                <div className="form__input-checkbox-mock">{this.props.text}</div>
            </div>)

        )
    }
}

export default Checkbox;