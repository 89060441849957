import React, {Component} from 'react';

/**
 * Pager page component.
 */
class Page extends Component {
    /**
     * Render page.
     *
     * @returns {*}
     */
    render() {
        return (
            <div
                className={`block__top-steps-single${this.props.currentStep >= this.props.num ? ' step-completed' : ''}`}>
                {this.props.num > 1 && <div className="block__top-steps-dot-sm block__top-steps-dot"></div>}
                {this.props.num > 1 && <div className="block__top-steps-dot-sm block__top-steps-dot"></div>}
                <a onClick={() => this.props.onStepClick(this.props.num)} className="block__top-steps-dot-lg block__top-steps-dot">{this.props.num}</a>
            </div>
        )
    }
}

export default Page;