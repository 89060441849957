import Input from "../Input";
import React from 'react';
import {default as ReactSelect} from 'react-select';
import validator from "validator";
import {FORM_ERROR_MESSAGES} from "../../Form";
import './Select.scss';

/**
 * Select component.
 */
class Select extends Input {
    /**
     * Select constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Prepare options for select input.
     *
     * @returns {[]}
     */
    prepareOptions() {
        let options = [];
        if (typeof this.props.options === 'object') {
            for (const [value, label] of Object.entries(this.props.options)) {
                options.push({value: value, label: label});
            }
        }
        return options;
    }

    /**
     * Validate select.
     *
     * @param value
     * @returns {boolean}
     */
    validate(value) {
        let errors = {};
        if (this.props.required) {
            if (validator.isEmpty(value, {ignore_whitespace: true})) {
                errors.isEmpty = FORM_ERROR_MESSAGES.isEmpty;
            }
        }
        if (value && !this.props.options.hasOwnProperty(value)) {
            errors.notExists = FORM_ERROR_MESSAGES.notExists;
        }
        this.setState({errors: errors});
        return Object.getOwnPropertyNames(errors).length === 0;
    }

    /**
     * Handle select change event.
     *
     * @param event
     */
    handleChange(event) {
        this.value = event.value;
        this.props.onChange(this.props.name, event.value);
    }

    /**
     * Render single select.
     *
     * @returns {*}
     */
    render() {
        let options = this.prepareOptions();

        const clearStyles = {
            control: () => ({}),
            menu: () => ({}),
            menuList: () => ({}),
            option: () => ({}),
            valueContainer: () => ({}),
            singleValue: () => ({})
        }

        return (
            this.renderField(<ReactSelect id={this.props.name} options={options} isClearable={false} placeholder="Wybierz..."
                                          isSearchable={false} onChange={this.handleChange} onBlur={this.handleBlur}
                                          classNamePrefix='form__input-select' styles={clearStyles}
                                          defaultValue={options.filter(o => o.value === this.props.value)}/>)
        )
    }
}

export default Select;