import React, {Component} from 'react';

import './Loader.scss';

/**
 * Loader component.
 */
class Loader extends Component {
    /**
     * Render loader.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="loader">
                <div className="loader-inner ball-pulse">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Loader;