import React, {Component} from 'react';
import Logo from '../Logo/Logo';
import './Header.scss';

/**
 * Header component.
 */
class Header extends Component {
    /**
     * Render header.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="app__header">
                <div className="container top-section">
                    <div className="top-section__inner">
                        <div className="top-section__image">
                            {this.props.logo && <Logo url={this.props.logo} alt={this.props.name}/>}
                        </div>
                        <div className={`top-section__content${this.props.isSuccess ? ' top-section__content-success' : ''}`}>
                            <div className="top-section__title">
                                <span className="font__heading">Formularz kontaktowy</span>
                            </div>
                            {
                                !this.props.isSuccess &&
                                <div className="top-section__description">
                                    <p>Prosimy o uzupełnienie poniższych punktów - informacje te pomogą nam w
                                        udzieleniu Państwu odpowiedzi.</p>
                                    <p>Dziękujemy za chęć podzielenia się z nami uwagami, pytaniami czy
                                        sugestiami
                                        dotyczącymi naszego produktu.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;