import React, {Component} from 'react';
import './Button.scss';

/**
 * Button component.
 */
class Button extends Component {
    /**
     * Render button.
     *
     * @returns {*}
     */
    render() {
        return (
            <button type="button"
                    className={`btn navigation__button${this.props.additionalClasses ? ' ' + this.props.additionalClasses : ''}`}
                    onClick={this.props.onClick}>
                <span>{this.props.text}</span>
            </button>
        )
    }
}

export default Button;