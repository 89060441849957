import React, {Component} from 'react';
import {FORM_TYPE_COMPLAINT, FORM_TYPE_ENQUIRY, FORM_TYPE_NAMES, FORM_TYPE_SUGGESTION} from "../../Form";
import FormType from "../../Fields/FormType";
import iconQuestion from '../../../../assets/images/question-mark.svg';
import iconExcalama from '../../../../assets/images/exclamation-mark.svg';
import iconDialogue from '../../../../assets/images/dialogue.svg';

/**
 * Step one component.
 */
class StepOne extends Component {
    /**
     * Render step one.
     *
     * @returns {*}
     */
    render() {
        let types = [
            {
                text: FORM_TYPE_NAMES[FORM_TYPE_COMPLAINT],
                icon: iconExcalama,
                type: FORM_TYPE_COMPLAINT
            },
            {
                text: FORM_TYPE_NAMES[FORM_TYPE_ENQUIRY],
                icon: iconQuestion,
                type: FORM_TYPE_ENQUIRY
            },
            {
                text: FORM_TYPE_NAMES[FORM_TYPE_SUGGESTION],
                icon: iconDialogue,
                type: FORM_TYPE_SUGGESTION
            },
        ];
        return (
            <div className="form__start">
                {
                    types.map((type, index) => (
                        <FormType text={type.text} icon={type.icon}
                                  active={this.props.formType === type.type}
                                  onClick={this.props.onTypeChange}
                                  type={type.type}
                                  key={index}
                        />
                    ))
                }
            </div>
        )
    }
}

export default StepOne;