import React, {Component} from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.scss';
import cross from '../../assets/images/cross.svg';

/**
 * Cookie consent component.
 */
class CookieConsent extends Component {
    /**
     * Store is agreed flag in state.
     *
     * @type {{agreed: boolean}}
     */
    state = {
        agreed: parseInt(Cookies.get('maspex_cookie_consent')) === 1
    };

    /**
     * CookieConsent constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleCookiesClose = this.handleCookiesClose.bind(this);
    }

    /**
     * Cookie close handler.
     */
    handleCookiesClose() {
        this.setState({agreed: 1})
        Cookies.set('maspex_cookie_consent', 1, {expires: 365});
    }

    /**
     * Render cookie consent.
     *
     * @returns {*}
     */
    render() {
        return (
            !this.state.agreed &&
            <div className="app__cookies">
                <div className="app__cookies-inner">
                    <div className="app__cookies-text">
                        Ta witryna wykorzystuje pliki cookies do przechowywania
                        informacji na Twoim komputerze. Pliki cookies stosujemy w celu świadczenia usług na
                        najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z
                        witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w
                        Twoim urządzeniu końcowym. W każdym momencie możesz dokonać zmiany ustawień dotyczących
                        cookies. <a href="https://maspex.com/politykaprywatnosci.pdf" target="_blank">Więcej szczegółów
                        w naszej Polityce Prywatności.</a>
                    </div>
                    <div className="app__cookies-close">
                        <a onClick={this.handleCookiesClose}><img src={cross}/></a>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default CookieConsent;