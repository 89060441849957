import Text from '../Text';
import React from "react";
import MaskedInput from 'react-text-mask'

/**
 * Postcode component.
 */
class Postcode extends Text {
    /**
     * Render postcode input.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(
                <MaskedInput id={this.props.name} type={this.props.htmlType} onChange={this.handleChange}
                             onBlur={this.handleBlur} value={this.props.value}
                             mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/]}/>
            )
        )
    }
}

export default Postcode;