import React, {Component} from 'react';
import './Footer.scss';

/**
 * Footer component.
 */
class Footer extends Component {
    /**
     * Return current year.
     *
     * @returns {number}
     */
    static currentYear() {
        const date = new Date();
        return date.getFullYear();
    }

    /**
     * Render footer.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="app__footer">
                <div className="app__footer-inner">
                    <div className="app__footer-copyright">
                        <span>© {Footer.currentYear()} Maspex, All rights reserved</span>
                    </div>
                    <div className="app__footer-logo">
                        <img src="https://maspex.com/assets/images/maspex-logo.png"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;