import Text from '../Text';
import React from "react";

/**
 * Textarea component.
 */
class Textarea extends Text {
    /**
     * Render textarea input.
     *
     * @returns {*}
     */
    render() {
        return (
            this.renderField(<textarea id={this.props.name} onChange={this.handleChange} onBlur={this.handleBlur}
                                       value={this.props.value}/>)
        )
    }
}

export default Textarea;