import React, {Component} from 'react';
import DOMPurify from 'dompurify'

/**
 * Input component. Every form field component should inherit this.
 */
class Input extends Component {
    /**
     * Input default state.
     *
     * @type {{errors: {}}}
     */
    state = {
        tooltipVisible: false,
        errors: {}
    };

    /**
     * Input value.
     *
     * @type {string|int}
     */
    value = this.props.value;

    /**
     * Input constructor.
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleTooltip = this.handleTooltip.bind(this);
    }

    /**
     * Handler for input blur action.
     *
     * @param event
     */
    handleBlur(event) {
        let value = DOMPurify.sanitize(this.value.trim());
        this.validate(value);
        this.props.onChange(this.props.name, value);
    }

    /**
     * Handle input change event.
     *
     * @param event
     */
    handleChange(event) {
        this.value = event.target.value;
        this.props.onChange(event.target.id, event.target.value);
    }

    /**
     * Handle tooltip hover event.
     */
    handleTooltip() {
        this.setState({tooltipVisible: !this.state.tooltipVisible})
    }

    /**
     * Returns true if input is valid, false otherwise.
     *
     * @returns {boolean}
     */
    isValid() {
        return Object.getOwnPropertyNames(this.state.errors).length === 0;
    }

    /**
     * Validate input.
     *
     * @param value
     * @returns boolean
     */
    validate(value) {
        return false;
    }

    /**
     * Prepare label for dangerouslySetInnerHTML method to allow html inside label (ie. for checkbox rules anchor).
     *
     * @param label
     * @returns {{__html: *}}
     */
    prepareLabel(label) {
        if (this.props.required) {
            label += '<span class="form__req-mark">*</span>';
        }
        return {__html: label};
    }

    /**
     * Render single field wrapped with necessary html.
     *
     * @param field
     * @returns {*}
     */
    renderField(field) {
        return (
            <div
                className={`form__line form__line-input__${this.props.type}${!this.isValid() ? ' form__line-wrong' : ''}${this.state.tooltipVisible ? ' form__tip-visible' : ''}`}>
                {
                    this.props.tooltip &&
                    <div className="form__tip-container">
                        <div className="form__tip-cloud">
                            {this.props.tooltip.title &&
                            <span className="form__tip-text">{this.props.tooltip.title}</span>}
                            <span className="form__tip-example">{this.props.tooltip.description}</span>
                        </div>
                    </div>
                }
                <div className="form__line-inner">
                    <div className="form__line-label">
                        <label htmlFor={this.props.name} className="form__label"
                               dangerouslySetInnerHTML={this.prepareLabel(this.props.label)}/>
                        {
                            this.props.tooltip &&
                            <div className="form__tip-question-mark-container">
                                <a onMouseEnter={this.handleTooltip} onMouseLeave={this.handleTooltip}
                                   className="form__tip-question-mark">?</a>
                            </div>
                        }
                    </div>
                    <div className="form__line-input">
                        <div className="form__input-container">
                            {field}
                        </div>
                    </div>
                </div>
                {!this.isValid() &&
                <div className="form__warning-container">
                    {
                        Object.keys(this.state.errors).map(key => (
                            <div className="form__warning-inner" key={key}>
                                <span className="form__warning-mark">!</span>
                                <span className="form__warning">{this.state.errors[key]}</span>
                            </div>
                        ))
                    }
                </div>
                }
            </div>
        )
    }
}

export default Input;