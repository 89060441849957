import React from 'react';
import StepTwo from "../StepTwo";
import {FORM_TYPE_COMPLAINT} from "../../Form";

/**
 * Step three component.
 */
class StepThree extends StepTwo {
    /**
     * Render step three.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="form__content">
                {this.prepareFields()}
                {
                    this.props.formType === FORM_TYPE_COMPLAINT &&
                    <div className="form__line form__line-input__checkbox">
                        <div className="form__line-inner">
                            <div className="form__line-label">
                            <span className="form__label">Przekazane dane osobowe przetwarzane będą w celu rozpatrzenia zgłoszonej reklamacji. Administratorem danych osobowych jest Grupa Maspex Sp. z o.o. Sp.k. z siedzibą w Wadowicach (ul. Legionów 37, 34-100 Wadowice). Kontakt z Administratorem możliwy jest poprzez adres e-mail: maspex@maspex.com. Administrator wyznaczył Inspektora Ochrony Danych, z którym można się skontaktować w każdej sprawie dotyczącej przetwarzania danych osobowych poprzez adres e-mail: iodreklamacje@maspex.com. Podanie danych osobowych jest dobrowolne, jednak ich niepodanie skutkuje brakiem możliwości rozpatrzenia reklamacji. Pełna treść informacji dotyczącej przetwarzania danych osobowych dostępna jest w <a
                                href="https://maspex.com/politykaprywatnosci.pdf"
                                target="_blank">Polityce Prywatności.</a></span>
                            </div>
                            <div className="form__line-input">
                                <div className="form__input-container">
                                    <span className="form__warning-mark">!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="form__line form__line-input__checkbox form__line-short-conditions">
                    <div className="form__line-inner">
                        <div className="form__line-label">
                            <span className="form__label">Strona korzysta z reCAPTCHA.
                                <a rel="nofollow" href="https://policies.google.com/privacy" target="_blank">Polityka prywatności</a> Google,
                                <a rel="nofollow" href="https://policies.google.com/terms" target="_blank"> Warunki korzystania</a> z usługi.
                            </span>
                        </div>
                        <div className="form__line-input">
                            <div className="form__input-container">
                                <span className="form__warning-mark">!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StepThree;