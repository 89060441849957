import React, {Component} from 'react';
import Page from './Page';

/**
 * Pager component.
 */
class Pager extends Component {
    /**
     * Create pager children.
     *
     * @param numPages
     * @returns {[]}
     */
    createPages = (numPages) => {
        let steps = [];
        for (let i = 0; i < numPages; i++) {
            steps.push(<Page num={i + 1} key={i} currentStep={this.props.currentStep} onStepClick={this.props.onStepClick}/>);
        }
        return steps;
    };

    /**
     * Render pager.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="block__top-steps">
                <div className="block__top-steps-inner">
                    <div className="block__top-steps-label">
                        <span className="">Krok:</span>
                    </div>
                    <div className="block__top-steps-content">
                        {this.createPages(3)}
                    </div>
                </div>
            </div>
        )
    }
}

export default Pager;