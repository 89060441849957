import React, {Component} from 'react';

/**
 * Form type field.
 */
class FormType extends Component {
    /**
     * Render field.
     *
     * @returns {*}
     */
    render() {
        return (
            <div className={`form__start-box${this.props.active ? ' form__start-box-active' : ''}`}
                 onClick={() => this.props.onClick(this.props.type)}>
                <div className="form__start-box-icon">
                    <span className="form__start-box-icon-circle"><img src={this.props.icon}/></span>
                </div>
                <div className="form__start-box-text">
                    <span>{this.props.text}</span>
                </div>
            </div>
        )
    }
}

export default FormType;